<template>
    <div class="projectcard">
        <div class="projectcard-detail">
            <h1 class="project-name">{{ data.name }}</h1>
            <p class="project-location">{{ data.location }}</p>
            <p class="project-cost" v-if="data.cost!=null">&#x20B9; {{ data.cost }}</p>
            <div class="projectcard-action">
                <router-link
                    :to="{
                        name: 'BuilderProjectDetailsPage',
                        params: { id: data.id },
                    }"
                >
                    <!-- <Button primary name="View Project" /> -->
                </router-link>
            </div>
        </div>
        <div class="projectcard-showcase">
            <div
                class="primary-img"
                v-for="(image, index) in [data.thumb, data.thumb2, data.thumb3]"
                :key="'primary_img_' + index"
            >
                <img :src="image == null ? require('assets/images/default-builder.png') : image"  :alt="data.name"/>
            </div>
        </div>
    </div>
</template>

<script>
// import Button from 'componentsv2/Button'

export default {
    name: 'ProjectCard',
    components: {
        // Button,
    },
    props: {
        data: {
            type: Object,
        },
    },
}
</script>
<style lang="scss">
@import './ProjectCard.scss';
</style>

<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="builder-detailpage">
            <!-- <Banner 
            img="builders_page/builderslistpage_banner_img.jpg"
            :heading="builderDetails.developer_data.name"
        /> -->
            <BreadCrumbs :current="builderDetails.developer_data.name" />
            <div class="head-display">
                <div class="display-image">
                    <div class="img">
                        <!-- <img src="@/assets/images/FAQ_top.webp" alt=""> -->
                    </div>
                    <div class="display-info">
                        {{ builderDetails.developer_data.name }}
                    </div>
                </div>
            </div>
            <div class="builderdetails-container">
                <div class="builderdetails-description">
                    <!-- <h1 class="builderdetails-name">{{builderDetails.developer_data.name}}</h1> -->
                    <p class="builderdetails-description">
                        {{ builderDetails.developer_data.description }}
                    </p>
                </div>
                <h1 class="ongoing-project">Ongoing Project</h1>
                <div class="builderdetails-row">
                    <div
                        v-for="project in builderDetails.projects_data"
                        :key="project.id"
                        class="builderdetails-col"
                    >
                        <ProjectCard :data="project" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import BuilderDetailsPageModule from 'store/modules/BuilderDetailsPage'
import IsVisible from 'mixins/IsVisible'

// import Banner from 'components/Banner'
import ProjectCard from 'components/ProjectCard'
// import PaginateButton from 'components/PaginateButton'
// import PaginateLargeButton from 'components/PaginateLargeButton'
import BreadCrumbs from 'componentsv2/BreadCrumbs'
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'BuilderDetailsPage',
    components: {
        // Banner,
        ProjectCard,
        // PaginateButton,
        // PaginateLargeButton,
        BreadCrumbs,
        Footer,
        Loader,
    },
    mixins: [RegisterStoreModule, IsVisible, loaderHandler],
    computed: {
        ...mapState({
            builderDetails: state =>
                state.BuilderDetailsPageModule.builderDetails,
        }),
    },
    created() {
        this.registerStoreModule(
            'BuilderDetailsPageModule',
            BuilderDetailsPageModule,
        )
        this.fetchBuilderDetails({ builder_id: this.$route.params.id }).then(
            response => {
                if (response.status == 'Success') {
                    document.querySelector(
                        "meta[property='og:title']",
                    ).content = `${this.builderDetails.developer_data.name}`

                    document.querySelector(
                        "meta[property='og:description']",
                    ).content = `${this.builderDetails.developer_data.description}`
                }
                // if (response.status != 'Success') {
                //     this.$router.push({ name: '404' })
                // }
                setTimeout(() => {
                    this.loaderShow = false
                }, 500)
            },
        ).catch(err=>{
            setTimeout(() => {
                this.loaderShow = false
            }, 500)
        })
    },
    destroyed() {
        this.$store.unregisterModule('BuilderDetailsPageModule')
    },
    methods: {
        ...mapActions({
            fetchBuilderDetails: 'fetchBuilderDetails',
        }),
    },
}
</script>

<style lang="scss">
@import './BuilderDetailsPage.scss';
</style>
